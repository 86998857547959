import { LineItem } from '@medusajs/medusa'
import { PortionSize, VariantList } from '../types/medusa'

const checkSafePortionSizeUpdate = (
    items: LineItem[],
    portionSize: string,
    allVariants: VariantList,
    mealTypeId: string
) => {
    if (!items) return []
    const allProductIds = items.reduce((acc: string[], item) => {
        return [...acc, item.variant.product_id]
    }, [])

    if (!allProductIds) {
        return []
    }

    if (allVariants) {
        return Object.keys(allVariants)
            ?.filter(productId => allProductIds?.includes(productId))
            ?.reduce((acc, productId) => {
                const product = allVariants[productId]
                const currentQuantity = items.find(item => item.variant.product_id === productId)?.quantity || 0
                if (
                    (product?.[portionSize as PortionSize] &&
                        product?.[portionSize as PortionSize]?.inventory_quantity >= currentQuantity) ||
                    product.type_id !== mealTypeId
                ) {
                    return acc
                }

                return [...acc, product.title]
            }, [] as string[])
    } else {
        return null
    }
}

export default checkSafePortionSizeUpdate
