'use client'

import * as React from 'react'
import { createElement } from 'react'
import { ButtonProps, ButtonRenderComponent } from './types'
import LoadingIcon from '../../icons/loadingIcon'
const classNames = require('classnames')

const Button = <T extends ButtonRenderComponent>({
    as,
    onClick,
    className,
    children,
    leftIcon,
    noChildren,
    rightIcon,
    loading,
    ...props
}: ButtonProps<T>): ReturnType<React.FC<ButtonProps<T>>> => {
    return createElement(
        as || 'button',
        {
            onClick: (e: MouseEvent) => {
                if (onClick) {
                    onClick(e)
                }
            },
            className: `btn ${className}`,
            disabled: loading ? true : false,
            ...props,
        },
        <>
            {leftIcon && leftIcon}
            {!noChildren && (
                <span className={classNames(``, { 'ml-2.5': leftIcon }, { 'mr-3': rightIcon || loading })}>
                    {children}
                </span>
            )}
            {!loading && rightIcon && rightIcon}
            {loading && <LoadingIcon />}
        </>
    )
}

export default Button
