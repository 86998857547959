import React, { FC, SVGAttributes } from 'react'

const CloseIcon: FC<SVGAttributes<SVGElement>> = ({ ...props }) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" fill="none" viewBox="0 0 40 40" {...props}>
    <path
      stroke="current"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="2"
      d="M15.923 15.923l8.154 8.154m0-8.154l-8.154 8.154"
    />
  </svg>
)

export default CloseIcon
