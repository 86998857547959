import { fbqEnabled, gtagEnabled, klaviyoEnabled } from '../tracking/index'
import { Cart } from '@medusajs/medusa'

export const trackBeginCheckout = (
    cart: Omit<Cart, 'refundable_amount' | 'refunded_total'>,
    paymentSessionId: string
) => {
    const items = cart?.items.map(item => {
        return {
            item_name: item.title,
        }
    })

    const klaviyoItems = cart?.items.map(item => {
        return {
            ProductName: item.title,
            ProductID: item.variant.product_id,
            ItemPrice: (item.unit_price! / 100).toString(),
            ImageURL: item.thumbnail,
            Quantity: item?.quantity,
            RowTotal: item?.total,
            ProductURL: `https://www.goodchef.nl/gerechten/${item.variant.product.handle}`,
        }
    })

    if (cart?.total) {
        const totalValue = cart?.total / 100
        if (gtagEnabled()) {
            gtag('event', 'begin_checkout', {
                value: totalValue,
                currency: 'EUR',
                items,
            })
        }
        if (fbqEnabled()) {
            fbq('track', 'InitiateCheckout', {
                event_id: cart.id,
                value: totalValue,
                currency: 'EUR',
                items,
            })
        }
        if (klaviyoEnabled()) {
            window._learnq.push([
                'track',
                'Started Checkout',
                {
                    event_id: cart.id,
                    items: klaviyoItems,
                    $value: totalValue,
                },
            ])
        }
    }
}
