import isBrowser from '../isBrowser'

//@ts-ignore
export const gtagEnabled = () => isBrowser && typeof window.gtag === 'function'

// @ts-ignore
export const klaviyoEnabled = () => !!process.env.NEXT_PUBLIC_KLAVIYO_API_KEY && typeof window._learnq === 'object'
//@ts-ignore
export const klaviyoFormEnabled = () =>
    !!process.env.NEXT_PUBLIC_KLAVIYO_API_KEY && typeof window._klOnsite === 'object'
// @ts-ignore
export const fbqEnabled = () => isBrowser && typeof window.fbq === 'function'
