'use client'
import { MedusaError } from 'medusa-core-utils'
import { medusaClient } from '../config'
import { handleError } from '../util/handle-error'
import { useCart, useCreateLineItem, useDeleteLineItem, useUpdateLineItem, useUpdateCart } from 'medusa-react'
import { Cart, Country, LineItem, Region, StorePostCartsCartReq } from '@medusajs/medusa'
import React, { PropsWithChildren, useEffect, useMemo, useState } from 'react'
import { useMutation } from '@tanstack/react-query'
import { CartSubscriptionContext, Order, PortionSize, Subscription, VariantList } from '../../types/medusa'
import { trackSubmittedDiscountForm } from '../../utils/tracking/trackSubmittedDiscountForm'
import { useBoolean } from 'ui/hooks/useBoolean'
import checkSafePortionSizeUpdate from '../../utils/checkSafePortionSizeUpdate'
import { useHandleBoxChange } from 'ui/hooks/useHandleBoxChange'
import routes from '@lib/routes'
import { useRouter } from 'next/navigation'
import Cookies from 'js-cookie'
import { trackBeginCheckout } from '../../utils/tracking/trackBeginCheckout'

type ErrorType = { response: { data: MedusaError } }

export type CheckoutFormValues = {
    email?: string
    shipping_address?: AddressValues
    billing_address?: AddressValues
    shipping_notice?: string
    shipping_notice_recurring?: boolean
    subscription?: object
}

export type VariantInfoProps = {
    variantId: string
    quantity: number
}

export type LineInfoProps = {
    lineId: string
    quantity: number
}

export type AddressValues = {
    first_name: string
    last_name: string
    company?: string
    address_1: string
    address_2: string
    city: string
    province?: string
    postal_code: string
    country_code: string
    phone: string
}

interface StoreContext {
    initPayment: () => Promise<void>
    setAddresses: (addresses: CheckoutFormValues) => void
    countryCode: string | undefined
    setRegion: (regionId: string, countryCode: string) => void
    addItem: (item: VariantInfoProps) => void
    allAvailableCountries: Country[]
    updateCartItems: (
        item?: VariantInfoProps[],
        context?: object,
        newCart?: boolean,
        callBack?: (cart: Cart) => void
    ) => void
    updateItem: (item: LineInfoProps) => void
    deleteItem: (lineId: string) => void
    resetCart: () => void
    checkBeforeChangeBoxProperty: (portion: string, subscriptionItems?: LineItem[]) => boolean
    setContext: (payload: any) => Promise<void>
    allVariants: VariantList
    mealTypeId: string
    mealsInCart: number
    addDiscountCode: (code: string, errorHandler: (error: string) => void, callBack: () => void) => void
    removeDiscountCode: (errorHandler: (error: string) => void, callBack: () => void) => void
    filters: Filter[] | undefined
    setFilters: (filters: Filter[]) => void
    hideHeader: boolean
    setHideHeader: (value: boolean) => void
    showCart: boolean
    setShowCart: (value: boolean) => void
    menuOpen: boolean
    setMenuOpen: (value: boolean) => void
    quantityError: string | undefined
    setQuantityError: (value: string | undefined) => void
    isPortionSizeWarningOpen: boolean
    closePortionSizeWarning: () => void
    openPortionSizeWarning: () => void
    isPortionTutorialOpen: boolean
    closePortionTutorial: () => void
    openPortionTutorial: () => void
    conflictingProducts: string[]
    handlePortionChange: (force?: boolean, subscriptionItems?: LineItem[], subscription?: Subscription) => void
    cartSubscriptionContext?: CartSubscriptionContext
    quantityUpdating?: { [key: string]: boolean }
    hasSeenSizeTutorial: boolean | undefined
    setHasSeenSizeTutorial: (value: boolean) => void
    setScrollPosition: (value: number | null) => void
    scrollPosition: number | null
    setPaymentProvider: (cartId: string, payload: any) => Promise<void | null>
    setPaymentSession: (cartId: string, providerId: string) => Promise<void | null>
}

const StoreContext = React.createContext<StoreContext | null>(null)

export const useStore = () => {
    const context = React.useContext(StoreContext)
    if (context === null) {
        throw new Error('useStore must be used within a StoreProvider')
    }
    return context
}

const IS_SERVER = typeof window === 'undefined'
const CART_KEY = 'medusa_cart_id'
const REGION_KEY = 'medusa_region'

type ReplaceLineItemsBody = {
    items: VariantInfoProps[]
    context?: object
}

type UpdatePaymentProviderBody = {
    context?: object
}

export type ChildFilter = {
    name: string
    checked: boolean
}

export type Filter = {
    name: string
    children: ChildFilter[]
}

export const StoreProvider = ({
    allVariants,
    allAvailableCountries,
    mealTypeId,
    allFilters,
    children,
}: PropsWithChildren<{
    allAvailableCountries: Country[]
    allFilters: Filter[]
    allVariants: VariantList
    mealTypeId: string
}>) => {
    const { cart, setCart, createCart, updateCart } = useCart()
    const cartSubscriptionContext = cart?.context?.subscription as CartSubscriptionContext
    const IDEMPOTENCY_KEY = 'create_payment_session_key'
    const [countryCode, setCountryCode] = useState<string | undefined>(undefined)
    const [hideHeader, setHideHeader] = useState(false)
    const [showCart, setShowCart] = useState(false)
    const [menuOpen, setMenuOpen] = useState(false)
    const [scrollPosition, setScrollPosition] = useState<number | null>(null)
    const [hasSeenSizeTutorial, setHasSeenSizeTutorial] = useState(false)
    const [filters, setFilters] = useState<Filter[] | undefined>(undefined)
    const [quantityError, setQuantityError] = useState<string | undefined>(undefined)
    const addLineItem = useCreateLineItem(cart?.id!)
    const removeLineItem = useDeleteLineItem(cart?.id!)
    const adjustLineItem = useUpdateLineItem(cart?.id!)
    const [conflictingProducts, setConflictingProducts] = useState<string[]>([])
    const { handleBoxChange } = useHandleBoxChange()
    const router = useRouter()

    const {
        value: isPortionSizeWarningOpen,
        setFalse: closePortionSizeWarning,
        setTrue: openPortionSizeWarning,
    } = useBoolean(false)
    const {
        value: isPortionTutorialOpen,
        setFalse: closePortionTutorial,
        setTrue: openPortionTutorial,
    } = useBoolean(false)
    const [quantityUpdating, setQuantityUpdating] = useState({})

    const { mutateAsync: updateCheckoutCart } = useUpdateCart(cart?.id!)
    const mealsInCart = useMemo(() => {
        return cart?.items
            ? cart?.items
                  .filter(item => item.variant.product.type_id === mealTypeId)
                  .reduce((prev, acc) => {
                      return prev + acc?.quantity
                  }, 0)
            : 0
    }, [cart])

    /**
     * Method to create the payment sessions available for the cart. Uses a idempotency key to prevent duplicate requests.
     */
    const createPaymentSession = async (cartId: string) => {
        return medusaClient.carts
            .createPaymentSessions(cartId, {
                'Idempotency-Key': IDEMPOTENCY_KEY,
            })
            .then(({ cart }) => cart)
            .catch(() => null)
    }

    const setPaymentSession = async (cartId: string, providerId: string) => {
        return medusaClient.carts
            .setPaymentSession(
                cartId,
                {
                    provider_id: providerId,
                },
                {
                    'Idempotency-Key': IDEMPOTENCY_KEY,
                }
            )
            .then(({ cart }) => setCart(cart))
            .catch(() => null)
    }

    /**
     * Method that calls the createPaymentSession method and updates the cart with the payment session.
     */
    const initPayment = async () => {
        if (cart?.id && cart?.items?.length) {
            if (!cart.payment_sessions?.length) {
                const paymentSession = await createPaymentSession(cart.id)
                if (paymentSession) {
                    trackBeginCheckout(cart, paymentSession.id)
                    setCart(paymentSession)
                }
            } else {
                trackBeginCheckout(cart, cart.payment_sessions[0]?.id)
            }
        }
    }

    const setAddresses = async (data: CheckoutFormValues) => {
        const payload: StorePostCartsCartReq = {
            shipping_address: data?.shipping_address,
            email: data?.email,
            billing_address: data?.billing_address,
            ...(data?.subscription
                ? {
                      context: {
                          shipping_notice: data?.shipping_notice,
                          shipping_notice_recurring: !!data?.shipping_notice_recurring,
                          subscription: {
                              // @ts-ignore
                              ...cart?.context?.subscription,
                              ...data.subscription,
                          },
                      },
                  }
                : {}),
        }

        await updateCheckoutCart(payload, {
            onSuccess: data => {
                if (data) {
                    setCart(data?.cart)
                }
            },
        })
        await initPayment()
    }

    const replaceLineItems = useMutation<Cart, unknown, ReplaceLineItemsBody>({
        mutationFn: variables =>
            medusaClient.client.request('POST', `/store/carts/${cart?.id!}/replace-line-items`, variables),
    })

    const updatePaymentProvider = useMutation<Cart, unknown, UpdatePaymentProviderBody>({
        mutationFn: variables =>
            medusaClient.client.request('POST', `/store/carts/${cart?.id}/update-payment-provider`, variables),
    })

    const checkBeforeChangeBoxProperty = (portionSize: string, subscriptionItems?: LineItem[]): boolean => {
        let canUpdate = false
        const items = subscriptionItems ? subscriptionItems : cart?.items
        if (items) {
            const notAvailableProducts = checkSafePortionSizeUpdate(items, portionSize, allVariants, mealTypeId)
            if (!notAvailableProducts || notAvailableProducts?.length === 0) {
                canUpdate = true
            } else {
                setConflictingProducts(notAvailableProducts)
                if (!subscriptionItems) {
                    openPortionSizeWarning()
                }
            }
        }

        return canUpdate
    }
    const handlePortionChange = async (
        force?: boolean,
        subscriptionItems?: LineItem[],
        activeSubscription?: Subscription
    ) => {
        const items = subscriptionItems ? subscriptionItems : cart?.items
        const order = activeSubscription?.upcoming_orders?.find((order: Order) => !order?.shipment_at)
        const portionSize = subscriptionItems
            ? cartSubscriptionContext?.portion_size
            : cartSubscriptionContext?.portion_size === 'single'
            ? 'double'
            : 'single'
        const shippingNotice = cart?.context?.shipping_notice ? (cart.context.shipping_notice as string) : ''
        const shippingNoticeRecurring = cart?.context?.shipping_notice_recurring
            ? (cart.context.shipping_notice_recurring as boolean)
            : false
        let canUpdate = true
        if (!force) {
            canUpdate = checkBeforeChangeBoxProperty(portionSize)
        }

        if (canUpdate) {
            let newItems: VariantInfoProps[] = []

            if (items) {
                newItems = items.reduce((acc: VariantInfoProps[], item) => {
                    const isMeal = item.variant.product.type_id === mealTypeId

                    const variant = allVariants?.[item.variant.product_id]?.[portionSize as PortionSize]

                    if (!isMeal) {
                        return [
                            ...acc,
                            {
                                variantId: item.variant_id!,
                                quantity: item.quantity,
                            },
                        ]
                    }

                    if (variant && variant.inventory_quantity >= item.quantity) {
                        return [
                            ...acc,
                            {
                                variantId: variant.id,
                                quantity: item.quantity,
                            },
                        ]
                    }

                    return acc
                }, [])
            }

            await updateCartItems(
                newItems,
                {
                    ...cart?.context,
                    subscription: {
                        ...cartSubscriptionContext,
                        portion_size: portionSize,
                    },
                    shipping_notice: shippingNotice,
                    shipping_notice_recurring: shippingNoticeRecurring,
                },
                false
            ).then(() => {
                setTimeout(() => {
                    closePortionTutorial()
                }, 500)
                if (activeSubscription && order) {
                    handleBoxChange(
                        activeSubscription,
                        newItems.map(item => ({
                            variantId: item.variantId!,
                            quantity: item.quantity,
                        })),
                        activeSubscription?.billing_interval,
                        order?.metadata?.portion_size,
                        activeSubscription?.renewal_at as unknown as string,
                        () => {
                            router.push(routes.account)
                        },
                        order?.metadata?.shipping_notice,
                        activeSubscription?.metadata?.shipping_notice_recurring as boolean
                    )
                }
            })
        }
    }

    const storeRegion = (regionId: string, countryCode: string) => {
        if (!IS_SERVER) {
            localStorage.setItem(REGION_KEY, JSON.stringify({ regionId, countryCode }))

            setCountryCode(countryCode)
        }
    }

    useEffect(() => {
        setHasSeenSizeTutorial(sessionStorage.getItem('hasSeenSizeTutorial') === 'true')
        if (!IS_SERVER) {
            const storedRegion = localStorage.getItem(REGION_KEY)
            if (storedRegion) {
                const { countryCode } = JSON.parse(storedRegion)
                setCountryCode(countryCode)
            }
        }
        if (!filters) {
            setFilters(allFilters)
        }
    }, [])

    useEffect(() => {
        if (hasSeenSizeTutorial && !sessionStorage.getItem('hasSeenSizeTutorial')) {
            sessionStorage.setItem('hasSeenSizeTutorial', 'true')
        }
    }, [hasSeenSizeTutorial])

    const getRegion = () => {
        if (!IS_SERVER) {
            const region = localStorage.getItem(REGION_KEY)
            if (region) {
                return JSON.parse(region) as { regionId: string; countryCode: string }
            }
        }
        return null
    }

    const setRegion = async (regionId: string, countryCode: string) => {
        await updateCart.mutateAsync(
            {
                region_id: regionId,
            },
            {
                onSuccess: ({ cart }) => {
                    if (cart) {
                        setCart(cart)
                        storeCart(cart?.id)
                        storeRegion(regionId, countryCode)
                    }
                },
                onError: error => {
                    if (process.env.NODE_ENV === 'development') {
                        console.error(error)
                    }
                },
            }
        )
    }

    const ensureRegion = (region: Region, countryCode?: string | null) => {
        if (!IS_SERVER) {
            const { regionId, countryCode: defaultCountryCode } = getRegion() || {
                regionId: region.id,
                countryCode: region.countries[0].iso_2,
            }

            const finalCountryCode = countryCode || defaultCountryCode

            if (regionId !== region.id) {
                setRegion(region.id, finalCountryCode)
            }

            storeRegion(region.id, finalCountryCode)
            setCountryCode(finalCountryCode)
        }
    }

    const storeCart = (id: string) => {
        if (!IS_SERVER) {
            localStorage.setItem(CART_KEY, id)
        }
    }

    const getCart = () => {
        if (!IS_SERVER) {
            return localStorage.getItem(CART_KEY)
        }
        return null
    }

    const deleteCart = () => {
        if (!IS_SERVER) {
            localStorage.removeItem(CART_KEY)
        }
    }

    const deleteRegion = () => {
        if (!IS_SERVER) {
            localStorage.removeItem(REGION_KEY)
        }
    }

    const createNewCart = async (regionId?: string, variables?: any) => {
        await createCart.mutateAsync(
            {
                region_id: regionId,
                ...variables,
                context: {
                    fbp: Cookies.get('_fbp'),
                    fbc: Cookies.get('_fbc'),
                    subscription: {
                        ...(cart?.context?.subscription || { portion_size: 'single' }),
                    },
                },
            },
            {
                onSuccess: ({ cart }) => {
                    if (cart) {
                        setCart(cart)
                        storeCart(cart?.id)
                        ensureRegion(cart?.region, cart?.shipping_address?.country_code)
                    }
                },
                onError: error => {
                    if (process.env.NODE_ENV === 'development') {
                        console.error(error)
                    }
                },
            }
        )
    }

    const resetCart = () => {
        deleteCart()

        const savedRegion = getRegion()

        createCart.mutate(
            {
                region_id: savedRegion?.regionId,
                context: {
                    subscription: {
                        portion_size: 'single',
                    },
                },
            },
            {
                onSuccess: ({ cart }) => {
                    if (cart) {
                        setCart(cart)
                        storeCart(cart?.id)
                        ensureRegion(cart?.region, cart?.shipping_address?.country_code)
                    }
                },
                onError: error => {
                    if (process.env.NODE_ENV === 'development') {
                        console.error(error)
                    }
                },
            }
        )
    }

    const setContext = async ({
        shipping_notice,
        shipping_notice_recurring,
        single,
        ...payload
    }: {
        shipping_notice?: string
        shipping_notice_recurring?: boolean
        differentBillingAddress?: boolean
        single?: boolean
        payload: any
    }) => {
        await updateCart.mutateAsync(
            {
                context: {
                    shipping_notice:
                        typeof shipping_notice === 'string' ? shipping_notice : cart?.context?.shipping_notice,
                    shipping_notice_recurring:
                        shipping_notice_recurring !== undefined
                            ? shipping_notice_recurring
                            : !!cart?.context?.shipping_notice_recurring,
                    single: single !== undefined ? single : !!cart?.context?.single,
                    subscription: {
                        ...(cart?.context?.subscription || {}),
                        ...payload,
                    },
                },
            },
            {
                onSuccess: ({ cart }) => {
                    if (cart) {
                        setCart(cart)
                        storeCart(cart?.id)
                    }
                },
                onError: error => {
                    if (process.env.NODE_ENV === 'development') {
                        console.error(error)
                    }
                },
            }
        )
    }

    const setPaymentProvider = async (cartId: string, payload: object) => {
        await updatePaymentProvider.mutateAsync(
            {
                context: payload,
            },
            {
                onSuccess: cart => {
                    if (cart) {
                        setCart(cart)
                        storeCart(cart?.id)
                    }
                },
                onError: error => {
                    if (process.env.NODE_ENV === 'development') {
                        console.error(error)
                    }
                },
            }
        )
    }

    const addDiscountCode = async (
        discountCode: string,
        errorHandler: (error: string) => void,
        callBack: () => void
    ) => {
        await updateCart.mutate(
            {
                discounts: [
                    {
                        code: discountCode,
                    },
                ],
            },
            {
                onSuccess: ({ cart }) => {
                    if (cart) {
                        setCart(cart)
                        callBack()
                        trackSubmittedDiscountForm(discountCode, true)
                    }
                },
                onError: errorObj => {
                    callBack()
                    const error = errorObj as unknown as ErrorType
                    trackSubmittedDiscountForm(discountCode, false, error.response.data.type)
                    if (error.response.data.type === 'not_found') {
                        errorHandler('Deze kortingscode is niet geldig.')
                    } else if (error.response.data.type === 'not_allowed') {
                        errorHandler('Deze kortingscode is niet geldig voor uw account.')
                    } else {
                        errorHandler('Er ging iets mis, probeer het nog een keer.')
                    }
                },
            }
        )
    }

    const removeDiscountCode = async (errorHandler: (error: string) => void, callBack: () => void) => {
        await updateCart.mutate(
            {
                discounts: [],
            },
            {
                onSuccess: ({ cart }) => {
                    if (cart) {
                        setCart(cart)
                        callBack()
                    }
                },
                onError: () => {
                    errorHandler('Er ging iets mis, probeer het nog een keer.')
                },
            }
        )
    }

    useEffect(() => {
        const queryString = new URLSearchParams(window.location.search)
        const cartIdFromUrl = queryString?.get('restore_checkout')
        if (cartIdFromUrl) {
            window.localStorage.setItem(CART_KEY, cartIdFromUrl)
        }
        const ensureCart = async () => {
            const cartId = getCart()
            const region = getRegion()
            if (cartId) {
                const cartRes = await medusaClient.carts
                    .retrieve(cartId)
                    .then(({ cart }) => {
                        // Remove out-of-stock items from cart
                        if (cart && cart?.items.length > 0) {
                            cart.items.map(item => {
                                if (item.variant && item.variant.inventory_quantity <= 0) {
                                    deleteItem(item.id)
                                }
                            })
                        }

                        return cart
                    })
                    .catch(async _ => {
                        return null
                    })

                if (!cartRes || cartRes.completed_at) {
                    deleteCart()
                    deleteRegion()
                    await createNewCart()
                    return
                }

                setCart(cartRes)
                ensureRegion(cartRes.region)
            } else {
                await createNewCart(region?.regionId)
            }
        }
        if (!IS_SERVER && (!cart?.id || cartIdFromUrl)) {
            ensureCart()
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const updateQuantityUpdating = (variantOrLineId: string, updating = true) => {
        setQuantityUpdating(prevState => ({ ...prevState, [variantOrLineId]: updating }))
    }

    const addItem = ({ variantId, quantity }: VariantInfoProps) => {
        updateQuantityUpdating(variantId)

        addLineItem.mutate(
            {
                variant_id: variantId,
                quantity: quantity,
            },
            {
                onSuccess: ({ cart }) => {
                    if (cart) {
                        setCart(cart)
                        storeCart(cart?.id)
                    }
                },
                onError: error => {
                    handleError(error)
                },
                onSettled: () => updateQuantityUpdating(variantId, false),
            }
        )
    }

    const deleteItem = (lineId: string) => {
        updateQuantityUpdating(lineId)

        removeLineItem.mutate(
            {
                lineId,
            },
            {
                onSuccess: ({ cart }) => {
                    if (cart) {
                        setCart(cart)
                        storeCart(cart?.id)
                    }
                },
                onError: error => {
                    handleError(error)
                },
                onSettled: () => updateQuantityUpdating(lineId, false),
            }
        )
    }

    const updateItem = ({ lineId, quantity }: LineInfoProps) => {
        updateQuantityUpdating(lineId)

        adjustLineItem.mutate(
            {
                lineId,
                quantity,
            },
            {
                onSuccess: ({ cart }) => {
                    if (cart) {
                        setCart(cart)
                        storeCart(cart?.id)
                    }
                },
                onError: error => {
                    handleError(error)
                },
                onSettled: () => updateQuantityUpdating(lineId, false),
            }
        )
    }

    const updateCartItems = async (
        lineItems?: VariantInfoProps[],
        context?: object,
        newCart?: boolean,
        callBack?: (cart: Cart) => void
    ) => {
        if (!newCart && (!cart?.id || replaceLineItems.isLoading)) {
            return
        }
        const region = getRegion()

        !newCart
            ? await replaceLineItems.mutateAsync(
                  {
                      items: lineItems || [],
                      ...(context
                          ? { context: { ...context } }
                          : // ? { context: { subscription: context, shipping_notice, shipping_notice_recurring } }
                            {}),
                  },
                  {
                      onSuccess: cart => {
                          if (cart) {
                              setCart(cart)
                              callBack && callBack(cart)
                              storeCart(cart?.id)
                          }
                      },
                      onError: errorObj => {
                          const error = errorObj as unknown as ErrorType
                          if (error.response.data.code === 'insufficient_inventory') {
                              setQuantityError('Het gekozen product is momenteel niet meer in voorraad.')
                          } else {
                              setQuantityError('Er ging iets mis, probeer het nogmaals of neem contact met ons op.')
                          }
                      },
                  }
              )
            : await createCart.mutateAsync(
                  {
                      region_id: region?.regionId,
                      items:
                          lineItems?.map(item => ({
                              variant_id: item.variantId!,
                              quantity: item.quantity!,
                          })) || [],
                      ...(context
                          ? { context: { ...context } }
                          : // ? { context: { subscription: context, shipping_notice, shipping_notice_recurring } }
                            {}),
                  },
                  {
                      onSuccess: data => {
                          if (cart) {
                              setCart(data?.cart)
                              storeCart(data?.cart?.id)
                          }
                      },
                      onError: error => {
                          if (process.env.NODE_ENV === 'development') {
                              console.error(error)
                          }
                      },
                  }
              )
    }

    return (
        <StoreContext.Provider
            value={{
                countryCode,
                setContext,
                setRegion,
                addItem,
                deleteItem,
                addDiscountCode,
                updateItem,
                resetCart,
                mealsInCart,
                updateCartItems,
                removeDiscountCode,
                filters,
                setFilters,
                hideHeader,
                setHideHeader,
                menuOpen,
                setMenuOpen,
                allVariants,
                mealTypeId,
                quantityError,
                setQuantityError,
                showCart,
                setShowCart,
                isPortionSizeWarningOpen,
                closePortionSizeWarning,
                openPortionSizeWarning,
                isPortionTutorialOpen,
                closePortionTutorial,
                openPortionTutorial,
                conflictingProducts,
                handlePortionChange,
                checkBeforeChangeBoxProperty,
                setAddresses,
                initPayment,
                cartSubscriptionContext,
                quantityUpdating,
                hasSeenSizeTutorial,
                setHasSeenSizeTutorial,
                scrollPosition,
                setScrollPosition,
                allAvailableCountries,
                setPaymentProvider,
                setPaymentSession,
            }}
        >
            {children}
        </StoreContext.Provider>
    )
}
