import { gtagEnabled } from '../tracking/index'

export const trackSubmittedDiscountForm = (discountCode: string, valid: boolean, reason?: string) => {
    const reasonProps = reason ? { inValidReason: reason } : {}
    if (gtagEnabled()) {
        gtag('event', 'submitted_discount_form', {
            discountCode,
            isValidCode: valid,
            ...reasonProps,
        })
    }
}
