import Medusa from '@medusajs/medusa-js'

// Defaults to standard port for Medusa server
let MEDUSA_BACKEND_URL = process.env.MEDUSA_BACKEND_URL || 'http://localhost:9000'

if (process.env.NEXT_PUBLIC_MEDUSA_BACKEND_URL) {
    MEDUSA_BACKEND_URL = process.env.NEXT_PUBLIC_MEDUSA_BACKEND_URL
}

const medusaClient = new Medusa({ baseUrl: MEDUSA_BACKEND_URL, maxRetries: 0 })

export { MEDUSA_BACKEND_URL, /*queryClient,*/ medusaClient }
