const routes = {
    login: '/login',
    account: '/account/mijn-maaltijdbox',
    editAddress: '/account/adresgegevens',
    editAccount: '/account/accountgegevens',
    orderHistory: '/account/order-geschiedenis',
    chooseBox: '/kies-je-box',
    products: '/gerechten',
    meals: '/gerechten',
    email: '/bestellen/email',
    details: '/bestellen/gegevens',
    delivery: '/bestellen/bezorging',
    payment: '/bestellen/betaling',
    checkPayment: '/bestellen/betaling-controleren',
    forgotPassword: '/wachtwoord-vergeten',
    resetPassword: '/reset-wachtwoord',
    paymentSuccess: '/bestellen/bedankt/:cart_id',
    paymentFailed: '/bestellen/betaling-mislukt',
    termsOfUse: '/algemene-voorwaarden',
    privacyPolicy: '/privacy-policy',
    faq: '/veelgestelde-vragen',
} as const

export default routes
