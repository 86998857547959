import { medusaClient } from 'web/src/lib/config'
import { VariantInfoProps } from 'web/src/lib/context/store-context'
import { Subscription } from 'web/src/types/medusa'
import { useEffect, useState } from 'react'
import { Order } from 'web/src/types/medusa'
import { formatPrice } from '../utils/formatPrice'

export const useHandleBoxChange = () => {
    const [isLoading, setIsLoading] = useState<boolean>(false)
    const [isSubmitted, setIsSubmitted] = useState<boolean>(false)
    const [error, setError] = useState<string>()

    const handleBoxChange = async (
        activeSubscription: Subscription,
        cartItems: VariantInfoProps[],
        billingInterval: number,
        portionSize: string,
        renewalAt: string,
        callback: (data: Subscription) => void,
        shippingNotice?: string,
        shippingNoticeRecurring?: boolean
    ) => {
        setError(undefined)
        setIsSubmitted(false)
        setIsLoading(true)

        await medusaClient.client
            .request(
                'POST',
                `/store/customers/me/subscriptions/order/${
                    activeSubscription?.upcoming_orders?.find((order: Order) => !order?.shipment_at)?.id
                }`,
                {
                    renewal_at: renewalAt,
                    billing_interval: billingInterval,
                    portion_size: portionSize,
                    items: cartItems,
                    shipping_notice: shippingNotice,
                    shipping_notice_recurring: !!shippingNoticeRecurring,
                }
            )
            .then(data => {
                if (data.error) {
                    setIsSubmitted(false)
                    setError(
                        `De aanpassingen aan de box bevatten een lager bedrag dan de aanbetaling van ${formatPrice(
                            data.error?.data?.paid_total / 100
                        )}.`
                    )
                } else {
                    setError(undefined)
                    setIsSubmitted(true)
                    callback(data)
                }
            })
            .finally(() => {
                setIsLoading(false)
            })
    }

    useEffect(() => {
        let submittedTimeout: NodeJS.Timeout

        if (isSubmitted) {
            submittedTimeout = setTimeout(() => {
                setIsSubmitted(false)
            }, 3000)
        }

        return () => {
            clearTimeout(submittedTimeout)
        }
    }, [isSubmitted])

    useEffect(() => {
        let errorTimeout: NodeJS.Timeout

        if (error) {
            errorTimeout = setTimeout(() => {
                setError(undefined)
            }, 3000)
        }

        return () => {
            clearTimeout(errorTimeout)
        }
    }, [error])

    return {
        error,
        isLoading,
        isSubmitted,
        handleBoxChange,
    }
}
